import authActionTypes from '../types/auth';

const initialState = {
    loggingIn: false,
    loggedIn: false,
    signingUp: false,
    signedUp: false,
    user: {},
    error: null,
    message: null,
    verificationStatus: false,
    resseting: false,
    reset: false,
    email: null,
    sendingCode: false,
    sentCode: false,
    confirmingCode: false,
    confirmedCode: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.LOGIN_STARTED: {
            return {
                ...state,
                loggingIn: true,
                error: null,
                message: null,
            }
        }
        case authActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                user: action.payload,
                error: null
            }
        }
        case authActionTypes.LOGIN_FAILED: {
            return {
                ...state,
                loggedIn: false,
                error: action.payload,
                loggingIn: false,
            }
        }
        case authActionTypes.SIGNUP_STARTED: {
            return {
                ...state,
                signingUp: true,
                signedUp: false,
                error: null,
                message: null,
            }
        }
        case authActionTypes.SIGNUP_SUCCESS: {
            return {
                ...state,
                signingUp: false,
                signedUp: true,
                error: null,
                message: action.payload,
            }
        }
        case authActionTypes.SIGNUP_FAILED: {
            return {
                ...state,
                error: action.payload,
                signingUp: false,
                signedUp: false,
            }
        }
        case authActionTypes.VERIFY_STATUS: {
            return {
                ...state,
                signingUp: false,
                signedUp: true,
                verificationStatus: action.verificationStatus,
                error: action.verificationStatus ? null : action.payload,
                message: action.verificationStatus ? action.payload : null,
            }
        }
        case authActionTypes.CHANGE_PASSWORD_STARTED: {
            return {
                ...state,
                updating: true,
                error: null,
            }
        }
        case authActionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                updating: false,
                error: null,
            }
        }
        case authActionTypes.CHANGE_PASSWORD_FAILED: {
            return {
                ...state,
                updating: false,
                error: action.payload,
            }
        }
        case authActionTypes.RESET_PASSWORD_STARTED: {
            return {
                ...state,
                resseting: true,
                reset: false,
                error: null,
                message: null,
            }
        }
        case authActionTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                resseting: false,
                reset: true,
                error: null,
                message: action.payload,
                email: action.email,
            }
        }
        case authActionTypes.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                resseting: false,
                reset: false,
                error: null,
                message: action.payload,
            }
        }
        case authActionTypes.SEND_CODE_STARTED: {
            return {
                ...state,
                sendingCode: true,
                sentCode: false,
                confirmingCode: false,
                confirmedCode: false,
                resseting: false,
                reset: false,
                error: null,
                message: null,
                email: null,
            }
        }
        case authActionTypes.SEND_CODE_SUCCESS: {
            return {
                ...state,
                sendingCode: false,
                sentCode: true,
                error: null,
                message: action.payload,
                email: action.email,
            }
        }
        case authActionTypes.SEND_CODE_FAILED: {
            return {
                ...state,
                sendingCode: false,
                sentCode: false,
                error:  action.payload,
                message:null,
            }
        }
        case authActionTypes.CONFIRM_CODE_STARTED: {
            return {
                ...state,
                confirmingCode: true,
                confirmedCode: false,
                error: null,
                message: null,
            }
        }
        case authActionTypes.CONFIRM_CODE_SUCCESS: {
            return {
                ...state,
                confirmingCode: false,
                confirmedCode: true,
                error: null,
                message: action.payload,
            }
        }
        case authActionTypes.CONFIRM_CODE_FAILED: {
            return {
                ...state,
                confirmingCode: false,
                confirmedCode: false,
                error:  action.payload,
                message: null,
            }
        }
        case authActionTypes.CLEAR_ERROR_MESSAGE: {
            return {
                ...state,                
                error: null,
            }
        }
        case "LOG_OUT": {
            return initialState
        }
        default: {
            return state;
        }
    }
}

export default authReducer;