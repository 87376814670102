export const endpoints = {
    // API_HOME2: "http://192.168.1.128:8082/api/v1",
    // API_HOME2: "https://dc-vercel-backend2-i5587mu5v-oghenekaroisrael.vercel.app/api/v1",
    API_HOME2: "https://dc-vercel-backend2-dot-deliveries-1222.uk.r.appspot.com/api/v1",
    // API_HOME: "http://192.168.1.128:3001/api/v1",
    API_HOME: "https://dc-backend1-dot-deliveries-1222.uk.r.appspot.com/api/v1",
    // API_HOME: "https://deliveries-1222.uk.r.appspot.com/api/v1",
}

export function getHeaders(auth = false, flutterwave = '') {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
  
    if (auth && token && flutterwave.length === 0) {
      headers['Authorization'] = `Bearer ${token}`;
    } 

    if (!auth && flutterwave.length > 0) {
        headers['Authorization'] = `Bearer ${flutterwave}`;
    }
  
    return headers;
  }