import userActionTypes from "../types/auth";
import { endpoints, getHeaders } from "../helpers/api";
import { redirect } from "./redirect.actions";
// import { setError } from './error.actions';

export const loginStarted = (payload) => {
  return {
    type: userActionTypes.LOGIN_STARTED,
  };
};

const loginSuccess = (payload) => {
  return {
    type: userActionTypes.LOGIN_SUCCESS,
    payload,
  };
};

export const loginFailed = (payload) => {
  return {
    type: userActionTypes.LOGIN_FAILED,
    payload,
  };
};

export const signupStarted = (payload) => {
  return {
    type: userActionTypes.SIGNUP_STARTED,
  };
};

const signupSuccess = (payload) => {
  return {
    type: userActionTypes.SIGNUP_SUCCESS,
    payload,
  };
};

export const signupFailed = (payload) => {
  return {
    type: userActionTypes.SIGNUP_FAILED,
    payload,
  };
};

export const verifyStatus = (payload, status) => {
  if (status === true) {
    return {
      type: userActionTypes.VERIFY_STATUS,
      payload,
      verificationStatus: true,
    };
  } else {
    return {
      type: userActionTypes.VERIFY_STATUS,
      payload,
      verificationStatus: false,
    };
  }
};

export const logoutRequest = () => {
  return {
    type: userActionTypes.LOGOUT_REQUEST,
  };
};

const changePasswordFailed = (payload) => {
  return {
    type: userActionTypes.CHANGE_PASSWORD_FAILED,
    payload,
  };
};

const changePasswordSuccess = () => {
  return {
    type: userActionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

const changePasswordStarted = () => {
  return {
    type: userActionTypes.CHANGE_PASSWORD_STARTED,
  };
};

const sendCodeFailed = (payload) => {
    return {
      type: userActionTypes.SEND_CODE_FAILED,
      payload,
    };
  };
  
  const sendCodeSuccess = (payload, email) => {
    return {
      type: userActionTypes.SEND_CODE_SUCCESS,
      payload,
      email,
    };
  };
  
  const sendCodeStarted = () => {
    return {
      type: userActionTypes.SEND_CODE_STARTED,
    };
  };

  const confirmCodeFailed = (payload) => {
    return {
      type: userActionTypes.CONFIRM_CODE_FAILED,
      payload,
    };
  };
  
  const confirmCodeSuccess = (payload) => {
    return {
      type: userActionTypes.CONFIRM_CODE_SUCCESS,
      payload,
    };
  };
  
  const confirmCodeStarted = () => {
    return {
      type: userActionTypes.CONFIRM_CODE_STARTED,
    };
  };

  const resetPasswordFailed = (payload) => {
    return {
      type: userActionTypes.RESET_PASSWORD_FAILED,
      payload,
    };
  };
  
  const resetPasswordSuccess = (payload, email) => {
    return {
      type: userActionTypes.RESET_PASSWORD_SUCCESS,
      payload,
      email,
    };
  };
  
  const resetPasswordStarted = () => {
    return {
      type: userActionTypes.RESET_PASSWORD_STARTED,
    };
  };

export const clearAuthErrorMessage = () => {
  return {
    type: userActionTypes.CLEAR_ERROR_MESSAGE,
  };
};

// Methods
export const loginRequest = (credentials) => async (dispatch) => {
    dispatch(loginStarted());
    try {
      const response = await fetch(endpoints.API_HOME + "/users/login?role=provider", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(credentials),
      });
  
      if (response.status === 200) {
        const { payload } = await response.json();
        localStorage.setItem("token", payload.access_token);
        dispatch(loginSuccess(payload.user));
      } else if (response.status === 401) {
        dispatch(loginFailed("Invalid username/password"));
      } else {
        const { error } = await response.json();
        dispatch(loginFailed(error));
      }
    } catch (error) {
      dispatch(loginFailed("Something weird happened"));
    }
  };
  

export const registerUser = (credentials) => async (dispatch) => {
  dispatch(signupStarted());

  try {
    const response = await fetch(endpoints.API_HOME + "/users/signup", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        ...credentials,
        role: "provider",
        status: "pending",
      }),
    });

    if (response.status === 201) {
      const data = await response.json();
      dispatch(signupSuccess(data.message + '!. Kindly Check Your Email To Verify Your Account!'));
    //   let verifyLink = data.link;

    //   const verifyResponse = await fetch(verifyLink);

    //   if (verifyResponse.status === 200) {
    //     const verifyResp = await verifyResponse.json();
    //     dispatch(
    //         verifyStatus(verifyResp.message, true)
    //     );
    //     dispatch(loginRequest(credentials));
    //   } else {
    //     dispatch(
    //         verifyStatus("Oops. An error occurred while verifying your account but check your email and try again!", false)
    //     );
    //   }
    } else {
      const errorResponse = await response.json();
      dispatch(signupFailed(errorResponse.error));
    }
  } catch (error) {
    dispatch(signupFailed("Oops. An error occurred"));
  }
};

export const autoLogin = () => (dispatch) => {
  const token = localStorage.getItem("token");
  if (token != null) {
    alert(token);
    fetch(endpoints.API_HOME + "/providers/auto-login", {
      headers: getHeaders(true),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          localStorage.setItem("token", data.token);
          dispatch(loginSuccess(data.user));
        } else {
          dispatch(loginFailed());
        }
      })
      .catch((err) => {
        dispatch(loginFailed());
      });
  } else {
    alert("nada");
  }
};

export const changePassword = (data) => (dispatch) => {
  dispatch(changePasswordStarted());
  if (data.password1 === data.password2) {
    fetch(endpoints.API_HOME + "/providers/change-password", {
      method: "POST",
      headers: getHeaders(true),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          dispatch(changePasswordSuccess());
          dispatch(redirect("/settings"));
        } else if (data.status === 401) {
          dispatch(changePasswordFailed("Invalid username/password"));
        } else {
          dispatch(changePasswordFailed("An error occured."));
        }
      })
      .catch((err) => {
        dispatch(changePasswordFailed("Network failure"));
      });
  } else {
    dispatch(changePasswordFailed("Passwords do not match"));
  }
};

  export const sendCode = (data) => async (dispatch) => {
    dispatch(sendCodeStarted());
    try {
      const response = await fetch(endpoints.API_HOME + "/users/reset-password-by-code", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      if (response.status === 200) {
        dispatch(sendCodeSuccess('Reset Code has been sent to your email!', data.email));
      } else if (response.status === 401) {
        dispatch(sendCodeFailed("Invalid username/password"));
      } else {
        dispatch(sendCodeFailed(responseData.error));
      }
    } catch (error) {
      dispatch(sendCodeFailed("Network failure"));
    }
  };

  export const confirmCode = (data) => async (dispatch) => {
    dispatch(confirmCodeStarted());
    try {
      const response = await fetch(endpoints.API_HOME + "/users/confirm-email", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      if (response.status === 200 && responseData.success) {
        dispatch(confirmCodeSuccess('Code Confirmed!'));
      } else {
        dispatch(confirmCodeFailed(responseData.error));
      }
    } catch (error) {
      dispatch(confirmCodeFailed("Network failure"));
    }
  };

  export const resetPassword = (data) => async (dispatch) => {
    dispatch(resetPasswordStarted());
    try {
      const response = await fetch(endpoints.API_HOME + "/users/change-password-with-code2", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      if (response.status === 200 ) {
        dispatch(resetPasswordSuccess('Password Has been Reset! Kindly go back to login'));
      } else if (response.status === 401) {
        dispatch(resetPasswordFailed("Invalid username/password"));
      } else {
        dispatch(resetPasswordFailed(responseData.error));
      }
    } catch (error) {
      dispatch(resetPasswordFailed("Network failure"));
    }
  };
  
