export default {
        
    LOGIN_STARTED: "LOGIN_STARTED",

    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    LOGIN_FAILED: "LOGIN_FAILED",

    SIGNUP_STARTED: "SIGNUP_STARTED",

    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",

    SIGNUP_FAILED: "SIGNUP_FAILED",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",

    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    
    LOGOUT_FAILED: "LOGOUT_FAILED",

    VERIFY_STATUS: "VERIFY_STATUS",

    CHANGE_PASSWORD_STARTED: "CHANGE_PASSWORD_STARTED",

    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    
    CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",

    RESET_PASSWORD_STARTED: "RESET_PASSWORD_STARTED",

    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",

    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

    SEND_CODE_STARTED: "SEND_CODE_STARTED",

    SEND_CODE_SUCCESS: "SEND_CODE_SUCCESS",

    SEND_CODE_FAILED: "SEND_CODE_FAILED",

    CONFIRM_CODE_STARTED: "CONFIRM_CODE_STARTED",

    CONFIRM_CODE_SUCCESS: "CONFIRM_CODE_SUCCESS",

    CONFIRM_CODE_FAILED: "CONFIRM_CODE_FAILED",

};