export default {
        
    FETCH_AGENTS_START: "FETCH_AGENTS_START",
    FETCH_AGENTS_SUCCESS: "FETCH_AGENTS_SUCCESS",
    FETCH_AGENTS_FAILURE: "FETCH_AGENTS_FAILURE",

    CREATE_AGENT_START: "CREATE_AGENT_START",
    CREATE_AGENT_SUCCESS: "CREATE_AGENT_SUCCESS",
    CREATE_AGENT_FAILURE: "CREATE_AGENT_FAILURE",

    DELETE_AGENT_START: "DELETE_AGENT_START",
    DELETE_AGENT_SUCCESS: "DELETE_AGENT_SUCCESS",
    DELETE_AGENT_FAILURE: "DELETE_AGENT_FAILURE",

    ASSIGN_DEFAULT_AGENT_START: "ASSIGN_DEFAULT_AGENT_START",
    ASSIGN_DEFAULT_AGENT_SUCCESS: "ASSIGN_DEFAULT_AGENT_SUCCESS",
    ASSIGN_DEFAULT_AGENT_FAILURE: "ASSIGN_DEFAULT_AGENT_FAILURE",
};