export default {
        
    FETCH_ASSETS_START: "FETCH_ASSETS_START",
    FETCH_ASSETS_SUCCESS: "FETCH_ASSETS_SUCCESS",
    FETCH_ASSETS_FAILURE: "FETCH_ASSETS_FAILURE",

    CREATE_ASSETS_START: "CREATE_ASSETS_START",
    CREATE_ASSETS_SUCCESS: "CREATE_ASSETS_SUCCESS",
    CREATE_ASSETS_FAILURE: "CREATE_ASSETS_FAILURE",

};