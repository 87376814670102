export default {
        
    FETCH_FARES_START: "FETCH_FARES_START",
    FETCH_FARES_SUCCESS: "FETCH_FARES_SUCCESS",
    FETCH_FARES_FAILURE: "FETCH_FARES_FAILURE",

    CREATE_FARE_START: "CREATE_FARE_START",
    CREATE_FARE_SUCCESS: "CREATE_FARE_SUCCESS",
    CREATE_FARE_FAILURE: "CREATE_FARE_FAILURE",

    DELETE_FARE_START: "DELETE_FARE_START",
    DELETE_FARE_SUCCESS: "DELETE_FARE_SUCCESS",
    DELETE_FARE_FAILURE: "DELETE_FARE_FAILURE",

    UPDATE_FARE_START: "UPDATE_FARE_START",
    UPDATE_FARE_SUCCESS: "UPDATE_FARE_SUCCESS",
    UPDATE_FARE_FAILURE: "UPDATE_FARE_FAILURE",

    FETCH_PROFILE_START: "FETCH_PROFILE_START",
    FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
    FETCH_PROFILE_FAILURE: "FETCH_PROFILE_FAILURE",
    
    UPDATE_COMPANY_DETAILS_START: "UPDATE_COMPANY_DETAILS_START",
    UPDATE_COMPANY_DETAILS_SUCCESS: "UPDATE_COMPANY_DETAILS_SUCCESS",
    UPDATE_COMPANY_DETAILS_FAILURE: "UPDATE_COMPANY_DETAILS_FAILURE",

    FETCH_COMPANY_DETAILS_START: "FETCH_COMPANY_DETAILS_START",
    FETCH_COMPANY_DETAILS_SUCCESS: "FETCH_COMPANY_DETAILS_SUCCESS",
    FETCH_COMPANY_DETAILS_FAILURE: "FETCH_COMPANY_DETAILS_FAILURE",

    FETCH_MANAGER_DETAILS_START: "FETCH_MANAGER_DETAILS_START",
    FETCH_MANAGER_DETAILS_SUCCESS: "FETCH_MANAGER_DETAILS_SUCCESS",
    FETCH_MANAGER_DETAILS_FAILURE: "FETCH_MANAGER_DETAILS_FAILURE",

    UPDATE_MANAGER_DETAILS_START: "UPDATE_MANAGER_DETAILS_START",
    UPDATE_MANAGER_DETAILS_SUCCESS: "UPDATE_MANAGER_DETAILS_SUCCESS",
    UPDATE_MANAGER_DETAILS_FAILURE: "UPDATE_MANAGER_DETAILS_FAILURE",

    UPDATE_OWNER_DETAILS_START: "UPDATE_OWNER_DETAILS_START",
    UPDATE_OWNER_DETAILS_SUCCESS: "UPDATE_OWNER_DETAILS_SUCCESS",
    UPDATE_OWNER_DETAILS_FAILURE: "UPDATE_OWNER_DETAILS_FAILURE",

    FETCH_OWNER_DETAILS_START: "FETCH_OWNER_DETAILS_START",
    FETCH_OWNER_DETAILS_SUCCESS: "FETCH_OWNER_DETAILS_SUCCESS",
    FETCH_OWNER_DETAILS_FAILURE: "FETCH_OWNER_DETAILS_FAILURE",

    UPDATE_IDENTIFICATION_DETAILS_START: "UPDATE_IDENTIFICATION_DETAILS_START",
    UPDATE_IDENTIFICATION_DETAILS_SUCCESS: "UPDATE_IDENTIFICATION_DETAILS_SUCCESS",
    UPDATE_IDENTIFICATION_DETAILS_FAILURE: "UPDATE_IDENTIFICATION_DETAILS_FAILURE",

    FETCH_IDENTIFICATION_DETAILS_START: "FETCH_IDENTIFICATION_DETAILS_START",
    FETCH_IDENTIFICATION_DETAILS_SUCCESS: "FETCH_IDENTIFICATION_DETAILS_SUCCESS",
    FETCH_IDENTIFICATION_DETAILS_FAILURE: "FETCH_IDENTIFICATION_DETAILS_FAILURE",

    UPDATE_INSURANCE_DETAILS_START: "UPDATE_INSURANCE_DETAILS_START",
    UPDATE_INSURANCE_DETAILS_SUCCESS: "UPDATE_INSURANCE_DETAILS_SUCCESS",
    UPDATE_INSURANCE_DETAILS_FAILURE: "UPDATE_INSURANCE_DETAILS_FAILURE",

    FETCH_INSURANCE_DETAILS_START: "FETCH_INSURANCE_DETAILS_START",
    FETCH_INSURANCE_DETAILS_SUCCESS: "FETCH_INSURANCE_DETAILS_SUCCESS",
    FETCH_INSURANCE_DETAILS_FAILURE: "FETCH_INSURANCE_DETAILS_FAILURE",

    UPDATE_PRICING_PREFERENCE_START: "UPDATE_PRICING_PREFERENCE_START",
    UPDATE_PRICING_PREFERENCE_SUCCESS: "UPDATE_PRICING_PREFERENCE_SUCCESS",
    UPDATE_PRICING_PREFERENCE_FAILURE: "UPDATE_PRICING_PREFERENCE_FAILURE",

    FETCH_PRICING_PREFERENCE_START: "FETCH_PRICING_PREFERENCE_START",
    FETCH_PRICING_PREFERENCE_SUCCESS: "FETCH_PRICING_PREFERENCE_SUCCESS",
    FETCH_PRICING_PREFERENCE_FAILURE: "FETCH_PRICING_PREFERENCE_FAILURE",

};